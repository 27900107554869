const $ = require("jquery")
const fullpage = require("fullpage.js")

require("jquery.easing")
require("bootstrap/js/src/tab")

const { TimelineMax, Power4, CSSPlugin, gsap } = require("gsap")
gsap.registerPlugin(CSSPlugin)

exports.onRouteUpdate = ({ location, prevLocation }) => {
  function toggleTheme(theme) {
    var body = document.body
    var themeToggle = document.querySelector(".theme-toggle")
    if (!themeToggle) {
      return
    }
    var themeToggleIcon = document.querySelector(".theme-toggle-icon")

    if (
      body.classList.contains("theme-blog-dark") ||
      theme === "theme-blog-dark"
    ) {
      console.log()

      themeToggleIcon.classList.remove("fa-sun")
      themeToggleIcon.classList.add("fa-moon")

      themeToggle.setAttribute("data-tip", "Dark Theme")

      body.classList.remove("theme-blog-dark")
      body.classList.add("theme-blog-light")
      window.localStorage.setItem("darkMode", "false")
    } else {
      themeToggleIcon.classList.remove("fa-moon")
      themeToggleIcon.classList.add("fa-sun")

      themeToggle.setAttribute("data-tip", "Light Theme")

      body.classList.remove("theme-blog-light")
      body.classList.add("theme-blog-dark")
      window.localStorage.setItem("darkMode", "true")
    }
  }
  //reset page when page changes
  $(function () {
    const body = $("body")
    body.removeClass("menu-is-open")
    if (window.fullpage_api) {
      window.fullpage_api.destroy("all")
    }
  })

  // Reveal on scroll
  $(function () {
    const options = {
      threshold: 0.2,
      rootMargin: "0px 0px 0px 0px",
    }

    function onEntry(entry) {
      entry.forEach((change, i) => {
        if (change.isIntersecting) {
          new TimelineMax({})
            .to(change.target, 0.5, {
              opacity: 1,
              delay: i - i * 0.9,
            })
            .to(
              change.target,
              2,
              {
                translateY: 0,
                ease: Power4.easeOut,
                delay: i - i * 0.9,
              },
              "-=1"
            )
            .call(function () {
              observer.unobserve(change.target)

              // change.target.classList.add('hoverable');
              // change.target.style.transform = '';
            })
        }
      })
    }

    // instantiate a new Intersection Observer
    let observer = new IntersectionObserver(onEntry, options)

    // list of cards
    let elements = document.querySelectorAll(
      ".consulting-content, .consulting-grid-thumb, .consulting-grid-content, .consulting-media, .about-media, .about-content, .about-images img, .ideals-card"
    )

    // loop through all elements
    // pass each element to observe method
    for (let elm of elements) {
      observer.observe(elm)
    }
  })

  //image BG
  $(function () {
    var image = document.querySelectorAll(
      'img[data-bg="true"], [data-bg="true"]'
    )

    for (var i = 0; i < image.length; i++) {
      ;(function (n) {
        var img = new Image()

        img.onload = imageFound
        img.onerror = imageNotFound
        img.src = image[n].src

        function imageFound() {
          image[n].parentNode.style.backgroundImage = "url(" + img.src + ")"

          if ($(image[n]).parent().is(".bg")) {
            $(image[n]).parent().css({
              opacity: "1",
            })
          }
        }

        function imageNotFound() {
          image[n].parentNode.style.backgroundColor = "#F3F5F7"
        }
      })(i)
    }
  })

  // Theme switcher
  $(function () {
    var themeToggle = document.querySelector(".theme-toggle")
    if (!themeToggle) {
      return
    }

    themeToggle.addEventListener("click", toggleTheme, false)
  })

  //image BG
  $(function () {
    var image = document.querySelectorAll(
      'img[data-bg-overlay="true"], [data-bg-overlay="true"]'
    )

    for (var i = 0; i < image.length; i++) {
      ;(function (n) {
        var img = new Image()

        img.onload = imageFound
        img.onerror = imageNotFound
        img.src = image[n].src

        function imageFound() {
          image[n].parentNode.style.backgroundImage =
            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), " +
            "url(" +
            img.src +
            ")"

          if ($(image[n]).parent().is(".bg")) {
            $(image[n]).parent().css({
              opacity: "1",
            })
          }
        }

        function imageNotFound() {
          image[n].parentNode.style.backgroundColor = "#F3F5F7"
        }
      })(i)
    }
  })

  // Fullpage
  $(function () {
    var timeoutId, a
    var animationIsFinished = false
    var delay = 600

    var fullPage = null

    var transitionArr = [
      ".featured-grid",
      ".section-content",
      ".section-media",
      ".hero-content",
      ".hero-media",
      ".intro-section-content-text > *",
    ]

    var sections = document.querySelectorAll(".full-page-section")

    if ($(".full-page").length > 0) {
      init()
      $(window).on("resize", init)
    }

    function init() {
      if ($(window).width() > 767) {
        fullPage = new fullpage(".full-page", {
          licenseKey: "BE655AD6-89254B08-BDA3CBE4-4925C81F",
          scrollingSpeed: 600,
          autoScrolling: true,
          sectionSelector: ".full-page-section",
          navigation: true,
          navigationPosition: "right",
          navigationTooltips: [
            "Creativity to New Heights!",
            "Life Mission",
            "Products",
            "Newsletter",
          ],
          // loopTop: true,
          afterRender: function () {
            sections[0].querySelectorAll(transitionArr).forEach((elm, i) => {
              new TimelineMax({}).to(elm, 1, {
                opacity: 1,
                translateY: 0,
                ease: Power4.easeOut,
                delay: i - i * 0.9,
              })
            })
          },
          onLeave: function (origin, destination, direction) {
            // Adjust color scheme
            $(".navbar, #fp-nav").css({
              color: $(destination.item).css("color"),
            })

            if (destination.item.querySelector("video") !== null) {
              destination.item.querySelector("video").play()
            }

            // Clear timeouts
            clearTimeout(a)

            $(".full-page-section").removeClass("is-visible")

            clearTimeout(timeoutId)

            timeoutId = setTimeout(function () {
              animationIsFinished = true

              window.fullpage_api.moveTo(destination.index + 1)

              a = setTimeout(function () {
                destination.item
                  .querySelectorAll(transitionArr)
                  .forEach((elm, i) => {
                    new TimelineMax({}).to(elm, 1, {
                      opacity: 1,
                      translateY: 0,
                      ease: Power4.easeOut,
                      delay: i - i * 0.9,
                    })
                  })

                // destination.item.classList.add('is-visible');
              }, 600)

              animationIsFinished = false
            }, delay)

            return animationIsFinished
          },
        })
      } else {
        if (fullPage !== null) {
          window.fullpage_api.destroy("all")
        } else {
          sections[0].querySelectorAll(transitionArr).forEach((elm, i) => {
            new TimelineMax({}).to(elm, 1, {
              opacity: 1,
              translateY: 0,
              ease: Power4.easeOut,
              delay: i - i * 0.9,
            })
          })
        }
      }
    }
  })

  // Sticky blog navigation
  $(function () {
    var articleContent = $(".blog-article-content"),
      articleNavigation = $(".blog-article-content-navigation"),
      w = $(window)

    var gutter = 30

    function stickyHero() {
      if (w.scrollTop() + gutter >= articleContent.offset().top) {
        if (w.width() > 1023) {
          articleNavigation.css({
            position: "fixed",
            top: `${gutter}px`,
            left:
              articleContent.offset().left +
              articleContent.innerWidth() -
              articleNavigation.innerWidth() +
              "px",
            width: "318px",
          })

          if (
            w.scrollTop() + gutter >=
            articleContent.innerHeight() +
              articleContent.offset().top -
              articleNavigation.innerHeight()
          ) {
            articleNavigation.css({
              position: "absolute",
              top:
                articleContent.innerHeight() +
                articleContent.offset().top -
                articleNavigation.innerHeight() +
                "px",
            })
          }
        } else {
          articleNavigation.css({
            position: "",
            top: "",
          })
        }
      } else {
        articleNavigation.css({
          position: "",
          top: "",
        })
      }

      requestAnimationFrame(stickyHero)
    }

    if (articleNavigation.length > 0) {
      stickyHero()
    }
  })

  // Menu
  $(function () {
    var toggler = $('[data-toggle="menu"]'),
      close = $('[data-dismiss="menu"]'),
      menu = $(".menu"),
      body = $("body")

    function toggleMenu(e) {
      if (!menu.hasClass("menu-open")) {
        body.addClass("menu-is-open")
        menu.addClass("menu-open")
      } else {
        body.removeClass("menu-is-open")
        menu.removeClass("menu-open")
      }
    }

    toggler.on("click", toggleMenu)
    close.on("click", toggleMenu)
  })

  // Sticky sidebar
  $(function () {
    var sidebar = $('[data-sidebar="true"]'),
      anchor = $('[data-sidebar-anchor="true"]'),
      w = $(window)

    var gutter = 20

    function sticky() {
      sidebar.each(function () {
        var that = $(this)

        if (w.width() > 1023) {
          if (w.scrollTop() >= sidebar.parent().offset().top - gutter) {
            that.css({
              position: "fixed",
              top: "0",
              paddingTop: gutter + "px",
              width: that.parent().width() + "px",
            })

            if (
              that.offset().top + that.height() + gutter >=
              anchor.offset().top + anchor.height()
            ) {
              that.css({
                position: "absolute",
                top: anchor.height() - that.height() - gutter + "px",
              })
            }
          } else {
            that.css({
              position: "",
              top: "",
              paddingTop: "",
              width: "",
            })
          }
        } else {
          that.css({
            position: "",
            top: "",
            paddingTop: "",
            width: "",
          })
        }
      })
    }

    function overflowAutoSidebar() {
      sidebar.each(function () {
        var that = $(this)

        if (that.height() > w.height()) {
          that.css({
            overflow: "auto",
            maxHeight: w.height() + "px",
            padding: "0 " + gutter + "px 0 0",
          })
        } else {
          that.css({
            overflow: "",
            maxHeight: "",
            padding: "",
          })
        }
      })
    }

    overflowAutoSidebar()

    // w.on('resize', overflowAutoSidebar);
    if (sidebar.length > 0) {
      w.on("scroll", sticky)
    }
  })
}
